import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from '../routes';

function AdminContent() {
  return (
    <Suspense>
      <Routes>
        {routes.map((route, index) => (
          route.adminElement && <Route
            key={index}
            path={route.path}
            element={<route.adminElement />}
          />
        ))}
      </Routes>
    </Suspense>
  )
}

export default AdminContent