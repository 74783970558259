import React, { Suspense,useEffect, useContext } from 'react';
import { routes } from '../routes';
import { Route, Routes,useLocation } from 'react-router-dom';
import { playlistData } from './DefaultLayout';

function AppContent() {
  const location = useLocation();
  // const [transitionClass, setTransitionClass] = useState('page-content');
  const context=useContext(playlistData)
  useEffect(() => {
    let interval;
      interval = setInterval(() => {
      context?.setProgress((prev) => {
        if (prev < 100) {
          return prev + 10;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
     })
    // setTransitionClass('page-content exiting');
    const timeout = setTimeout(() => {
      // setTransitionClass('page-content entered');
      context?.setProgress(0);
    }, 500);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [location]);
  return (
    <Suspense>
      {/* <div className={transitionClass}> */}
      <Routes>
        {routes.map((route, index) => (
          route.element && <Route
            key={index}
            path={route.path}
            element={<route.element />}
          />
        ))}
      </Routes>
      {/* </div>      */}
    </Suspense>
  )
}

export default AppContent